<template>
  <v-card class="mx-auto pa-5">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="10">
            <div style="padding: 0 10px;">
              <p class="text-header">Tracking number: <b>{{ item.tracking_number ? item.tracking_number : 'Chưa có' }}</b></p>
              <p class="text-header" v-if="item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL">Build Tracking Result: <b>{{ getTrackingFailReason }}</b></p>
            </div>
            <div style="padding: 0 10px;">
              <p class="text-header" v-if="item.departed_date && item.status == 3"> Trạng thái đơn hàng: <b> Departed</b></p>
              <p class="text-header" v-else> Trạng thái đơn hàng: <b> {{ orderGetStatusText(item.status) }}</b></p>
            </div>
            <div style="padding: 0 10px;">
              <p class="text-header">Người tạo: <b>{{ item.customer ? item.customer.name : '' }}</b></p>
              <p class="text-header">Phone: <b> {{ item.customer ? item.customer.phone : '' }}</b></p>
              <p class="text-header">Email: <b> {{ item.customer ? item.customer.email : '' }}</b></p>
            </div>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn color="primary" @click="onClickCopy">Copy order</v-btn>
          </v-col>
        </v-row>
        <v-container>
          <v-row no-gutters>
            <div class="form-detail">
              <p class="title-detail font-weight-bold">Thông tin gửi & nhận hàng</p>
              <v-row>
                <v-col cols="4" md="5">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Mã đơn hàng :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.code }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Tên đơn hàng :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.name }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Dịch vụ:</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">
                        {{ item.service ? item.service.name : '' }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="item.tax_code">
                    <v-col cols="5">
                      <v-card-text class="py-1">Mã số thuế:</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">
                        {{ item.tax_code }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Giá trị đơn hàng (USD)</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.price ? '$' + item.price : '' }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Địa chỉ hồi trả đơn hàng :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.return_address }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Ghi chú :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.note }}</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="0" md="2"></v-col>
                <v-col cols="12" md="5">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Người nhận :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_name }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Số điện thoại :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_phone }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Địa chỉ :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_address }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Thành phố :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_city }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Bang :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_state }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Quốc gia :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{
                          getCountryName(item.receiver_country_id)
                        }}
                      </v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Mã bưu điện :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_post_code }}</v-card-text>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="5">
                      <v-card-text class="py-1">Ghi chú :</v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_note }}</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" style="text-align: center;">
                  <a  @click="onClickPrintLable" style="color: #ef5350;font-size: 15px;">In tem dán cho đơn hàng</a>
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-container>
        <v-container>
          <div>
            <p class="title-col title-order font-weight-bold color-main">Thông tin kiện hàng</p>
          </div>
          <!--          <PackageDetail :order_code="order.code" :packages="order.packages"></PackageDetail>-->
          <v-row>
            <v-col cols="4" md="5">
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Nhận hàng vận chuyển từ :</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ getIncomeName(item.income_shipment_id) }}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Mã vận đơn gửi đến :</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.income_shipment_code }}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Tên kiện hàng :</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.package_name }}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Kho nhận hàng
                    :
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.receiver_city }}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Ghi chú của khách hang
                    :
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.customer_note ? item.customer_note : '' }}</v-card-text>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="0" md="2"></v-col>
            <v-col cols="12" md="5">
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Chiều dài (cm) :</v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.length }}</v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Chiều rộng (cm)
                    :
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.width }}
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Chiều cao (cm)
                    :
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.height }}</v-card-text>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="5">
                  <v-card-text class="py-1">Cân nặng (g)
                    :
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-text class="font-weight-bold py-1 grey--text text--darken-2 text-wrap">{{ item.weight }}</v-card-text>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div>
            <p class="title-col title-order font-weight-bold color-main my-4">Danh sách hàng</p>
          </div>
          <v-row v-if="item.order_items && item.order_items.length > 0">
            <v-col cols="12" md="12">
              <v-card outlined class="mx-2">
                <v-data-table :headers="tblHeader" :items="item.order_items" hide-default-footer>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <template v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR || (UserRole === CONSTANTS.ROLES.RECEIVER && item.service_id === CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS )">
            <UpdateOrderFee v-bind:order_fees.sync="item.order_fees" :orderId="item.id"></UpdateOrderFee>
          </template>
        </v-container>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import UpdateOrderFee from "./Update/UpdateOrderFee.vue";

export default {
  name: 'DetailOrder',
  props: ['selectedItem', 'onClose', 'onClickPrintLable'],
  components: { UpdateOrderFee },
  data: () => ({
    item: {},
    tblHeader: [
      {
        text: 'Tên hàng hoá và chất liệu',
        value: 'name',
        sortable: false,
        groupable: false,
      },
      {
        text: 'Link mô tả hàng hoá',
        value: 'link',
        sortable: false,
        groupable: false,
      },
      {
        text: 'Giá trị hàng',
        sortable: false,
        groupable: false,
        value: 'price',
      },
      {
        text: 'Số lượng',
        value: 'quantity',
        sortable: false,
        groupable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      orderGetStatusText: 'order/getStatusText',
      getCountryName: 'country/getName',
      incomeShipments: 'income_shipment/all',
      warehouses: 'warehouse/all'
    }),
    getTrackingFailReason() {
      if (!this.item.tracking_fail || this.item.tracking_fail.length < 1) return ''
      let lastItem = null;
      this.item.tracking_fail.forEach(record => {
        if (!lastItem) lastItem = record;
        if (lastItem.id < record.id) lastItem = record
      })
      if (lastItem.result.length <1) return ''
      return JSON.parse(lastItem.result).message
    },
  },
  methods: {
    ...mapActions({}),
    getIncomeName(id) {
      let name = '';
      this.incomeShipments.forEach(item => {
        if (item.id == id) {
          name = item.name;
        }
      });
      return name;
    },
    onClickCopy() {
      this.$emit('clickCopy');
    },
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    },
  }
}
</script>
<style scoped>
.icon-qr.v-icon.v-icon {
  color: #ef5350;
  top: -1px;
  font-size: 20px;
}

.btn-close {
  position: absolute;
  top: 16px;
  right: 24px;
  color: #ef5350;
  caret-color: #ef5350;
}

.chip.color-main {
  margin: 8px 0 5px 0;
  height: 23px;
}

.form-detail {
  display: block;
  width: 100%;
  border: 1px solid #ddd;
}

.title-detail {
  padding: 15px 15px 0 15px;
  margin-bottom: 0px;
}

.form-detail >>> .v-subheader {
  height: 32px;
}

p.title-order {
  padding: 10px;
  margin: 0;
}

.text-header {
  display: inline-block;
  margin-right: 30px;
}

.text-right {
  text-align: right;
}
</style>
